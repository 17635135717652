$(document).ready(function() {

  // $("#next_first").on("click", function() {
  //   $(".basic_info").css("display", "none");
  //   $(".business_info").css("display", "block");
  // });
  // $("#next_second").on("click", function() {
  //   $(".business_info").css("display", "none");
  //   $(".payment").css("display", "block");
  // });
});

$("#next_first").on("click", function() {
  $(".basic_info").css("display", "none");
  $(".business_info").css("display", "block");
});
$("#next_second").on("click", function() {
  $(".business_info").css("display", "none");
  $(".payment").css("display", "block");
});